<template>
  <div class="admin-archive-forms" :class="{skeleton: !state.loaded}">
    <div class="header">
      <div class="left">
        <span>총 {{ state.total || 0 }}건</span>
      </div>
      <label class="right" :for="`${component.name}Search`">
        <input type="text" class="form-control form-sm" v-model="state.args.keyword" @keyup.enter="search()" placeholder="분류 이름을 입력해주세요">
        <span class="reset pointer" @click="search(true)" v-if="$route.query.keyword">&times;</span>
        <button class="btn" @click="search()">
          <i class="fa fa-search"></i>
        </button>
      </label>
    </div>
    <div class="table-responsive" v-if="!state.loaded || state.categories.length">
      <table class="table">
        <thead>
        <tr>
          <th>
            <span>번호</span>
          </th>
          <th>
            <span>분류 이름</span>
          </th>
          <th>
            <span>분류 URL</span>
          </th>
          <th>
            <span>제약 여부</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(c, idx) in state.categories" :key="idx">
          <td>
            <span>{{ state.total - state.args.page * state.args.size - idx }}</span>
          </td>
          <td>
            <a class="color-anchor pointer" @click="edit(c)">{{ c.title }}</a>
          </td>
          <td>
            <template v-if="c.constraintFlag === 'Y'">
              <a class="pointer-u" :href="getUrl(c.id)" target="_blank" rel="noopener noreferrer" title="해당 페이지 이동">{{ getUrl(c.id) }}</a>
              <span class="pointer ml-2 badge badge-primary" @click="copy(getUrl(c.id))">복사</span>
            </template>
            <span v-else>(N/A)</span>
          </td>
          <td>
            <span>{{ c.constraintFlag }}</span>
          </td>
        </tr>
        </tbody>
      </table>
      <PaginationJpa v-if="state.pageable.size" :change="load" :pageable="state.pageable" :total="state.total" className="btn-purple"/>
    </div>
    <NoData v-else/>
    <a class="add" @click="add()">
      <i class="fa fa-plus"></i>
    </a>
  </div>
</template>

<script>import {defineComponent, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Wait from "@/components/Wait";
import http from "@/scripts/http";
import NoData from "@/components/NoData";
import PaginationJpa from "@/components/PaginationJpa";
import ArchiveMultiSelect from "@/components/ArchiveMultiSelect";
import store from "@/scripts/store";
import lib from "@/scripts/lib";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageAdminArchiveCategories";
  this.initialize = initialize;
}

export default defineComponent({
  components: {ArchiveMultiSelect, PaginationJpa, NoData, Wait},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      loaded: false,
      args: {
        page: 0,
        size: 10,
        keyword: "",
      },
      pageable: {
        page: 0,
        size: 0,
      },
      total: 0,
      categories: []
    });

    const urlPrefix = [location.origin, "/archive/categories/"].join("");

    const load = (page) => {
      (page !== undefined && page !== null && page !== window.Number(router.app.$route.query.page)) && router.push({
        query: {
          ...router.app.$route.query,
          page,
        }
      });
      
      state.loaded = false;
      state.total = 0;
      state.categories = [];
      state.args.page = window.Number(router.app.$route.query.page) || 0;
      state.args.keyword = router.app.$route.query.keyword || "";
      state.pageable = {
        page: 0,
        size: 0,
      };

      state.args.page = page || 0;

      http.get("/api/admin/archive/categories", state.args).then(({data}) => {
        state.loaded = true;
        state.categories = data.body.content;
        state.pageable = data.body.pageable;
        state.total = data.body.total;
      });
    };

    const getUrl = (id) => {
      return urlPrefix + id;
    };

    const copy = (value) => {
      lib.copyToClipboard(value);
      store.commit("setSwingMessage", "클립보드에 복사하였습니다.");
    };

    const add = () => {
      store.commit("openModal", {
        name: "ArchiveCategory",
        params: {
          category: {
            ord: state.total + 1
          },
        },
        callback: `${component.name}.load`,
      });
    };

    const edit = (category) => {
      store.commit("openModal", {
        name: "ArchiveCategory",
        params: {
          edit: true,
          category,
        },
        callback: `${component.name}.load`
      });
    };

    const search = (remove) => {
      const query = lib.getRenewed(router.app.$route.query);

      if (remove) {
        delete query.keyword;
      } else {
        query.keyword = state.args.keyword?.trim();
      }

      query.page = 0;
      router.push({query});
    };

    watch(() => router.app.$route.query, (next, prev) => {
      if (JSON.stringify(next) === JSON.stringify(prev) || (next.modals || prev.modals)) {
        return;
      }

      load(null, true);
    });

    return {component, state, load, getUrl, copy, add, edit, search};
  }
});
</script>

<style lang="scss" scoped>
.admin-archive-forms {
  padding-bottom: $px25;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: $px1 solid $colorBorder;
      border-radius: $px4;
      transition: border 0.18s;

      .form-control {
        font-size: $px12;
        border: 0;
      }

      .reset {
        padding: $px12;
      }

      .btn {
        font-size: $px12;
        border: 0;
        background: $colorBackground;
        height: $formHeight;
      }

      &:focus-within {
        border: $px1 solid $colorPurple;
      }
    }
  }

  .table-responsive {
    table {
      tr {
        th, td {
          padding-left: $px10;
          padding-right: $px10;
        }

        th {
          background: $colorBackground;
          border-bottom: 0;
        }
      }
    }

    .pagination {
      margin-top: $px30;
    }
  }

  .add {
    background: $colorPurple;
    bottom: $px30;
    border-radius: 50%;
    display: inline-block;
    height: $px60;
    padding: 0;
    left: 100%;
    width: $px60;
    cursor: pointer;
    text-align: center;
    position: sticky;
    transition: background-color 0.18s;
    z-index: 110;

    .fa-plus {
      color: #fff;
      font-size: $px22;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background: $colorPurpleActive;
    }
  }
}
</style>